var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

(function ($) {
  'use strict';

  Drupal.behaviors.inventoryStatusV1 = {
    /**
     * Flag to ensure this JS is only attached once.
     * @type {boolean}
     */
    attached: false,
    update: function ($statusList, skuData, skuBaseId) {
      var status = skuData.INVENTORY_STATUS;

      if (!skuData || !status) {
        return null;
      }

      if (skuData.SKU_ID !== 'SKU' + skuBaseId) {
        return null;
      }

      $statusList.find('.product__inventory-status-item').addClass('hidden');

      if (!!skuData.isLimitedRemaining) {
        $('.js-limited-remaining', $statusList).removeClass('hidden');
      }

      var $addBtn = $statusList.siblings('.js-add-to-cart');

      $addBtn.removeClass('button--disabled').data('disabled', false);
      $addBtn.text($addBtn.data('default-label'));

      var $statusToShow = $('.js-inv-status-' + status, $statusList);

      if ($statusToShow.length > 0) {
        var $btnLabels = $addBtn.data('inv-status-labels');

        if (!!$btnLabels && !!$btnLabels[status]) {
          $addBtn.text($btnLabels[status]);
        }

        if (!skuData.isShoppable) {
          $addBtn.addClass('button--disabled').data('disabled', true);
        }

        $statusToShow.removeClass('hidden');

        // Waitlist / Notify Me
        if (Drupal.settings.common && !!Drupal.settings.common.has_waitlist) {
          if (
            (!!Drupal.settings.common.display_notify_tos && status == 2) ||
            (!!Drupal.settings.common.display_notify_cs && status == 3)
          ) {
            $addBtn.text($addBtn.data('notify-me'));
            $addBtn.removeClass('button--disabled').data('disabled', false);
            if (!Drupal.settings.common.enable_toos_cs_status_msg) {
              $statusList.find('.product__inventory-status-item').addClass('hidden');
            }
            $('.js-notify-me', $statusList).removeClass('hidden');
          }
        }
        $statusList.trigger('inv_status_display:updated');
      }
      $statusList.removeClass('preorder-container');
      $('.js-spp-bag-bar--v1').removeClass('pre-order-sold-mbl');
      if (Drupal.settings.common && Drupal.settings.common.is_preorder) {
        if (skuData.isPreOrder) {
          Drupal.behaviors.inventoryStatusV1.preOrder(skuData, skuBaseId, $statusList);
        }
      }
    },
    preOrder: function (skuData, skuBaseId, statusList) {
      var activePreOrderStatus = $('.pre_order_active_msg', statusList);
      var soldPreOrderStatus = $('.pre_order_sold_msg', statusList);
      var outStockPreOrderStatus = $('.pre_order_out_stock_msg', statusList);
      var preOrderStatus = $(statusList).parents('div.js-product');
      var $addBtn = preOrderStatus.find('a.js-add-to-cart');
      var addBtnMbl = $('.js-spp-bag-bar--v1');
      var regOutStock = $('.temp-out-of-stock', statusList);
      var preNotifyMe = $('.js-notify-me', statusList);
      var imgSrc = preOrderStatus.find('.js-product-large-image-badge');

      imgSrc.removeClass('hidden');
      imgSrc.attr('src', site.translations.product.pre_order_spp_pre_badge);
      statusList.addClass('preorder-container');
      if (skuData.INVENTORY_STATUS == 1) {
        activePreOrderStatus.text(site.translations.product.pre_order_active);
        $addBtn.removeClass('pre-order-disabled');
        if ($addBtn.length) {
          $addBtn.text(site.translations.product.pre_order_text);
        }
        this.setPreorderMessage(activePreOrderStatus, activePreOrderStatus.html(), skuData.RELEASE_DATE, true);
      } else if (skuData.INVENTORY_STATUS == 2) {
        $addBtn.addClass('button--disabled').data('disabled', true);
        preNotifyMe.addClass('hidden');
        addBtnMbl.addClass('pre-order-sold-mbl');
        regOutStock.addClass('hidden');
        if ($addBtn.length) {
          $addBtn.addClass('pre-order-disabled');
          $addBtn.text(site.translations.product.pre_order_out_stock_cta);
          this.setPreorderMessage($addBtn, $addBtn.html(), skuData.RELEASE_DATE, false);
        }
        this.setPreorderMessage(outStockPreOrderStatus, outStockPreOrderStatus.html(), skuData.RELEASE_DATE, false);
      } else if (skuData.INVENTORY_STATUS == 7) {
        addBtnMbl.addClass('pre-order-sold-mbl');
        regOutStock.addClass('hidden');
        if ($addBtn.length) {
          $addBtn.addClass('pre-order-disabled');
          $addBtn.text(site.translations.product.pre_order_sold_out_cta);
          this.setPreorderMessage($addBtn, $addBtn.html(), skuData.RELEASE_DATE, false);
        }
        this.setPreorderMessage(soldPreOrderStatus, soldPreOrderStatus.html(), skuData.RELEASE_DATE, false);
      }
    },
    setPreorderMessage: function ($ele, trKey, dateStr, calculateShipDays) {
      if (dateStr) {
        var releaseSkuDateStr = '' + dateStr;
        var releaseSkuDate = new Date();

        releaseSkuDate.setFullYear(
          parseInt('20' + releaseSkuDateStr.substr(0, 2)),
          parseInt(releaseSkuDateStr.substr(2, 2)) - 1,
          parseInt(releaseSkuDateStr.substr(4, 2))
        );
        if (calculateShipDays) {
          var shipDays = parseInt(site.translations.product.pre_order_shipDays);
          var days = releaseSkuDate.getDate();

          if (!isNaN(shipDays)) {
            days += shipDays;
            releaseSkuDate.setDate(days);
          }
        }
        var releaseDay = ('0' + releaseSkuDate.getDate()).slice(-2);
        var releaseMonth = ('0' + (releaseSkuDate.getMonth() + 1)).slice(-2);
        var releaseDateReplace = releaseMonth + '/' + releaseDay;
        var msg = $ele.html();

        msg = msg.replace(/::release_date::/, releaseDateReplace);
        $ele.html(msg);
        $ele.removeClass('hidden');
      }
    },
    setEvents: function (context) {
      var self = this;

      self.attached = true;
      $(document).on('inv_status_data:updated', '.js-product', function (e) {
        $('.inventory-status--v1', context).each(function (i) {
          var $statusList = $(this);
          var skuBaseId = $statusList.data('sku-base-id');
          var skuData = prodcat.data.getSku(skuBaseId);

          if (!!skuData) {
            self.update($statusList, skuData, skuBaseId);
          }
        });
      });

      $(document).on('product.skuSelect', '.js-product', function (e, skuBaseId) {
        $('.inventory-status--v1', context).each(function (i) {
          var $statusList = $(this);
          var skuData = prodcat.data.getSku(skuBaseId);

          if ($(this).hasClass('js-quickshop')) {
            $statusList = $(this).find('.js-inv-status-list');
          }

          self.update($statusList, skuData, skuBaseId);
        });
      });

      $(document).on('product.init', '.js-product', function (e) {
        $('.inventory-status--v1', context).each(function (i) {
          var $statusList = $(this);
          var skuBaseId = $statusList.data('sku-base-id');
          var skuData = prodcat.data.getSku(skuBaseId);

          if (!!skuData) {
            self.update($statusList, skuData, skuBaseId);
          }
        });
      });
    },
    attach: function (context, settings) {
      if (this.attached) {
        return;
      }
      this.setEvents(context);
    }
  };
})(jQuery);
